import React, { useState } from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  Link,
  Flex,
  Image,
} from "@chakra-ui/react";
import logo from "../assets/logos/logo.png";

const Terms = () => {
  const [language, setLanguage] = useState("en");

  const t = (key) => {
    const translations = {
      en: {
        title: "Terms of Service for Digital Coupon Book Canada",
        effectiveDate: "Effective Date: September 6, 2024",
        introduction: `Welcome to Digital Coupon Book Canada (“we,” “us,” or “our”), a platform dedicated to assisting organizations in raising funds through the sale of digital coupon books. By accessing our website and using our platform, you agree to comply with and be bound by the following terms and conditions (“Terms of Service”). These Terms of Service govern your access to and use of our services, including any content, functionality, and products offered through our platform (collectively, the “Services”).`,
        acceptanceOfTerms: "1. Acceptance of Terms",
        acceptanceOfTermsContent: `By accessing or using our Services, you agree to these Terms of Service, our Privacy Policy, and any other policies or guidelines we may provide. If you do not agree with these terms, you must immediately stop using our Services.`,
        eligibility: "2. Eligibility",
        eligibilityContent: `To use our Services, you must be at least 18 years old and capable of entering into legally binding contracts under applicable law. By accessing or using the Services, you represent and warrant that you meet these eligibility requirements.`,
        servicesOverview: "3. Services Overview",
        servicesOverviewContent: `Digital Coupon Book Canada is a platform that allows organizations to raise funds by selling digital coupon books. Each coupon book contains offers from local businesses, such as discounts, buy-one-get-one (BOGO) offers, or other promotions. Coupons are available to customers upon purchasing the digital book, and businesses listed agree to honor these offers in accordance with the terms specified on each coupon.`,
        userAccounts: "4. User Accounts",
        userAccountsContent: `To access certain features of the Services, you may be required to create an account. When creating your account, you agree to:`,
        userAccountsList: [
          "Provide accurate, current, and complete information.",
          "Maintain and promptly update your information as necessary.",
          "Keep your login details confidential and secure.",
          "Keep your ‘secure code’ confidential and safe.",
        ],
        userAccountsFooter: `You are responsible for any activity that occurs under your account. If you suspect unauthorized use of your account, please contact us immediately.`,
        useOfCoupons: "5. Use of Coupons",
        useOfCouponsContent: `Each coupon is redeemed either through the use of our application OR through the use of the user’s ‘secure code’. This code is unique and should not be shared or posted anywhere. Coupons may not be combined with other third-party discount programs. Each coupon included in the digital coupon book is subject to the specific terms provided by the business offering the discount or promotion. The terms may include:`,
        useOfCouponsList: [
          "Expiration dates.",
          "Specific product or service restrictions.",
          "Limitations on the number of times the coupon can be used.",
        ],
        useOfCouponsFooter: `Coupons are non-transferable and can only be redeemed once per customer, per business, unless otherwise specified.`,
        diningOffers: "5.1 Dining Offers",
        diningOffersContent: `Dining offers are valid at all times throughout the year unless stated specifically in the offer detail or on the following days: All Official Canadian and Quebec Public Holidays; Valentine's Day; Mother's Day; and Father's Day. Only one coupon may be used per party or table. The specific rules for the various types of offers are outlined below:`,
        diningOffersList: [
          {
            title: "Main course offers.",
            content: `The restaurant will deduct the price of the least expensive main course(s), up to the maximum value stated on the offer. If two main courses are not ordered, the least expensive item(s) ordered as a main course will be deducted.`,
          },
          {
            title: "Take-out and Delivery.",
            content: `Unless otherwise stated coupons are only valid for dine-in.`,
          },
        ],
        businessParticipation: "6. Business Participation",
        businessParticipationContent: `Businesses that participate in Digital Coupon Book Canada do so voluntarily. By submitting a promotion or coupon for inclusion, businesses agree to honor the terms of the offer as presented to users. Businesses may not modify or cancel offers after inclusion without prior notice to Digital Coupon Book Canada and affected customers. We do not guarantee any specific outcome from a business’s participation in our platform. However, Digital Coupon Book Canada is committed to ensuring a fair and transparent process for all businesses and customers using the service.`,
        fundraisingOrganizations: "7. Fundraising Organizations",
        fundraisingOrganizationsContent: `Organizations raising funds through the sale of digital coupon books agree to:`,
        fundraisingOrganizationsList: [
          "Distribute the coupon books in accordance with their stated fundraising goals.",
          "Use the funds raised for legitimate and lawful purposes.",
          "Comply with all applicable laws and regulations.",
        ],
        fundraisingOrganizationsFooter: `We reserve the right to verify the legitimacy of fundraising activities and may, at our discretion, decline or terminate an organization’s use of the platform.`,
        paymentsAndFees: "8. Payments and Fees",
        paymentsAndFeesContent: `When purchasing a digital coupon book, the full payment must be made at the time of purchase. All sales are final, and no refunds will be issued unless required by law or in cases of significant error. Prices for digital coupon books may vary depending on the organization selling them.`,
        intellectualProperty: "9. Intellectual Property",
        intellectualPropertyContent: `All content, design, and functionality on the Digital Coupon Book Canada platform are the exclusive property of Digital Coupon Book Canada, unless otherwise specified. You may not reproduce, distribute, modify, or create derivative works from any part of our Services without prior written consent from us.`,
        privacy: "10. Privacy",
        privacyContent: `We may disclose to third parties aggregate anonymous statistics regarding our customers, sales, traffic analyses and other information regarding or collected through our websites and printed forms. Other than in this anonymous form, we will not disclose your personal information without first obtaining your permission except as follows:`,
        privacyList: [
          "We may disclose personal information if required by law or legal process.",
          "We may disclose your personal information to third parties providing, or assisting us in providing, all information communication technology, website or app development and production functions, website or app analytics, services requested by you and for billing purposes.",
          "We may disclose your personal information to the fundraiser that sold you your digital coupon book or you have selected at purchase of membership so that the fundraiser may notify you of new editions and other relevant initiatives.",
          "We may disclose your personal information provided voluntarily as part of the restaurant appraisal process to the restaurant you appraised, so that the restaurant can provide you with information that it thinks you may be interested in about that restaurant.",
        ],
        privacyFooter: `To view the full privacy policy please click here`,
        disclaimerOfWarranties: "11. Disclaimer of Warranties",
        disclaimerOfWarrantiesContent: `We provide the Services “as is” and “as available” without any warranties, express or implied, including but not limited to merchantability, fitness for a particular purpose, or non-infringement. We make no representations or warranties that:`,
        disclaimerOfWarrantiesList: [
          "The Services will meet your requirements.",
          "The Services will be uninterrupted, timely, secure, or error-free.",
          "Any information obtained through the Services will be accurate or reliable.",
        ],
        limitationOfLiability: "12. Limitation of Liability",
        limitationOfLiabilityContent: `In no event shall Digital Coupon Book Canada, its affiliates, partners, employees, or agents be liable for any direct, indirect, incidental, consequential, or special damages, including but not limited to loss of revenue, data, or business opportunities, arising from or in connection with the use of or inability to use the Services.`,
        termination: "13. Termination",
        terminationContent: `We reserve the right to terminate or suspend your access to the Services at any time, with or without cause, and without notice. Upon termination, you remain bound by these Terms of Service to the extent that they apply.`,
        changesToTerms: "14. Changes to Terms of Service",
        changesToTermsContent: `We may update these Terms of Service from time to time. Any changes will be effective immediately upon posting to our website. By continuing to use the Services, you accept and agree to the revised terms. It is your responsibility to review these terms regularly.`,
        governingLaw: "15. Governing Law",
        governingLawContent: `These Terms of Service are governed by the laws of Canada. Any disputes arising out of or related to these terms or your use of the Services shall be subject to the exclusive jurisdiction of the courts located in Ontario.`,
        contactUs: "16. Contact Us",
        contactUsContent: `If you have any questions about these Terms of Service, please contact us at`,
        contactUsEmail: `support@digitalcouponbook.ca`,
      },
      fr: {
        title: "Conditions d'utilisation de Digital Coupon Book Canada",
        effectiveDate: "Date d'entrée en vigueur : 6 septembre 2024",
        introduction: `Bienvenue chez Digital Coupon Book Canada (« nous », « notre » ou « nos »), une plateforme dédiée à aider les organisations à collecter des fonds grâce à la vente de livrets de coupons numériques. En accédant à notre site Web et en utilisant notre plateforme, vous acceptez de vous conformer et d'être lié par les conditions générales suivantes (« Conditions d'utilisation »). Ces Conditions d'utilisation régissent votre accès et votre utilisation de nos services, y compris tout contenu, fonctionnalité et produits offerts via notre plateforme (collectivement, les « Services »).`,
        acceptanceOfTerms: "1. Acceptation des conditions",
        acceptanceOfTermsContent: `En accédant ou en utilisant nos Services, vous acceptez ces Conditions d'utilisation, notre Politique de confidentialité et toutes les autres politiques ou directives que nous pouvons fournir. Si vous n'acceptez pas ces conditions, vous devez immédiatement cesser d'utiliser nos Services.`,
        eligibility: "2. Éligibilité",
        eligibilityContent: `Pour utiliser nos Services, vous devez avoir au moins 18 ans et être capable de conclure des contrats juridiquement contraignants en vertu de la loi applicable. En accédant ou en utilisant les Services, vous déclarez et garantissez que vous remplissez ces conditions d'éligibilité.`,
        servicesOverview: "3. Aperçu des services",
        servicesOverviewContent: `Digital Coupon Book Canada est une plateforme qui permet aux organisations de collecter des fonds en vendant des livrets de coupons numériques. Chaque livret de coupons contient des offres d'entreprises locales, telles que des réductions, des offres d'achat-un-obtenez-un-gratuit (BOGO) ou d'autres promotions. Les coupons sont disponibles pour les clients lors de l'achat du livre numérique, et les entreprises répertoriées s'engagent à honorer ces offres conformément aux conditions spécifiées sur chaque coupon.`,
        userAccounts: "4. Comptes utilisateurs",
        userAccountsContent: `Pour accéder à certaines fonctionnalités des Services, vous pouvez être amené à créer un compte. Lors de la création de votre compte, vous acceptez de :`,
        userAccountsList: [
          "Fournir des informations exactes, à jour et complètes.",
          "Maintenir et mettre à jour rapidement vos informations si nécessaire.",
          "Garder vos identifiants de connexion confidentiels et sécurisés.",
          "Garder votre ‘code sécurisé’ confidentiel et en sécurité.",
        ],
        userAccountsFooter: `Vous êtes responsable de toute activité qui se produit sous votre compte. Si vous soupçonnez une utilisation non autorisée de votre compte, veuillez nous contacter immédiatement.`,
        useOfCoupons: "5. Utilisation des coupons",
        useOfCouponsContent: `Chaque coupon est échangé soit par l'utilisation de notre application, soit par l'utilisation du 'code sécurisé' de l'utilisateur. Ce code est unique et ne doit pas être partagé ou publié où que ce soit. Les coupons ne peuvent pas être combinés avec d'autres programmes de réduction tiers. Chaque coupon inclus dans le livret de coupons numérique est soumis aux conditions spécifiques fournies par l'entreprise offrant la réduction ou la promotion. Les conditions peuvent inclure :`,
        useOfCouponsList: [
          "Dates d'expiration.",
          "Restrictions spécifiques sur les produits ou services.",
          "Limitations sur le nombre de fois où le coupon peut être utilisé.",
        ],
        useOfCouponsFooter: `Les coupons sont non transférables et ne peuvent être échangés qu'une seule fois par client, par entreprise, sauf indication contraire.`,
        diningOffers: "5.1 Offres de restauration",
        diningOffersContent: `Les offres de restauration sont valables à tout moment de l'année, sauf indication contraire dans les détails de l'offre ou les jours suivants : tous les jours fériés officiels canadiens et québécois ; la Saint-Valentin ; la fête des Mères ; et la fête des Pères. Un seul coupon peut être utilisé par groupe ou table. Les règles spécifiques pour les différents types d'offres sont décrites ci-dessous :`,
        diningOffersList: [
          {
            title: "Offres de plats principaux.",
            content: `Le restaurant déduira le prix du plat principal le moins cher, jusqu'à la valeur maximale indiquée sur l'offre. Si deux plats principaux ne sont pas commandés, l'article le moins cher commandé en tant que plat principal sera déduit.`,
          },
          {
            title: "À emporter et livraison.",
            content: `Sauf indication contraire, les coupons ne sont valables que pour les repas sur place.`,
          },
        ],
        businessParticipation: "6. Participation des entreprises",
        businessParticipationContent: `Les entreprises qui participent à Digital Coupon Book Canada le font volontairement. En soumettant une promotion ou un coupon pour inclusion, les entreprises acceptent d'honorer les conditions de l'offre telles que présentées aux utilisateurs. Les entreprises ne peuvent pas modifier ou annuler les offres après inclusion sans préavis à Digital Coupon Book Canada et aux clients concernés. Nous ne garantissons aucun résultat spécifique de la participation d'une entreprise à notre plateforme. Cependant, Digital Coupon Book Canada s'engage à garantir un processus équitable et transparent pour toutes les entreprises et les clients utilisant le service.`,
        fundraisingOrganizations: "7. Organisations de collecte de fonds",
        fundraisingOrganizationsContent: `Les organisations qui collectent des fonds par la vente de livrets de coupons numériques s'engagent à :`,
        fundraisingOrganizationsList: [
          "Distribuer les livrets de coupons conformément à leurs objectifs de collecte de fonds déclarés.",
          "Utiliser les fonds collectés à des fins légitimes et légales.",
          "Se conformer à toutes les lois et réglementations applicables.",
        ],
        fundraisingOrganizationsFooter: `Nous nous réservons le droit de vérifier la légitimité des activités de collecte de fonds et pouvons, à notre discrétion, refuser ou mettre fin à l'utilisation de la plateforme par une organisation.`,
        paymentsAndFees: "8. Paiements et frais",
        paymentsAndFeesContent: `Lors de l'achat d'un livret de coupons numérique, le paiement intégral doit être effectué au moment de l'achat. Toutes les ventes sont finales et aucun remboursement ne sera effectué, sauf si la loi l'exige ou en cas d'erreur importante. Les prix des livrets de coupons numériques peuvent varier selon l'organisation qui les vend.`,
        intellectualProperty: "9. Propriété intellectuelle",
        intellectualPropertyContent: `Tout le contenu, la conception et la fonctionnalité de la plateforme Digital Coupon Book Canada sont la propriété exclusive de Digital Coupon Book Canada, sauf indication contraire. Vous ne pouvez pas reproduire, distribuer, modifier ou créer des œuvres dérivées à partir de n'importe quelle partie de nos Services sans notre consentement écrit préalable.`,
        privacy: "10. Confidentialité",
        privacyContent: `Nous pouvons divulguer à des tiers des statistiques anonymes agrégées concernant nos clients, nos ventes, nos analyses de trafic et d'autres informations concernant ou collectées via nos sites Web et nos formulaires imprimés. Hormis sous cette forme anonyme, nous ne divulguerons pas vos informations personnelles sans avoir obtenu au préalable votre autorisation, sauf dans les cas suivants :`,
        privacyList: [
          "Nous pouvons divulguer des informations personnelles si la loi ou une procédure judiciaire l'exige.",
          "Nous pouvons divulguer vos informations personnelles à des tiers fournissant, ou nous aidant à fournir, toutes les technologies de communication d'information, les fonctions de développement et de production de sites Web ou d'applications, les analyses de sites Web ou d'applications, les services que vous avez demandés et à des fins de facturation.",
          "Nous pouvons divulguer vos informations personnelles au collecteur de fonds qui vous a vendu votre livret de coupons numérique ou que vous avez sélectionné lors de l'achat de l'adhésion afin que le collecteur de fonds puisse vous informer des nouvelles éditions et autres initiatives pertinentes.",
          "Nous pouvons divulguer vos informations personnelles fournies volontairement dans le cadre du processus d'évaluation du restaurant au restaurant que vous avez évalué, afin que le restaurant puisse vous fournir des informations qu'il pense pouvoir vous intéresser à propos de ce restaurant.",
        ],
        privacyFooter: `Pour consulter la politique de confidentialité complète, veuillez cliquer ici`,
        disclaimerOfWarranties: "11. Avis de non-responsabilité",
        disclaimerOfWarrantiesContent: `Nous fournissons les Services « tels quels » et « selon leur disponibilité » sans aucune garantie, expresse ou implicite, y compris, mais sans s'y limiter, les garanties de qualité marchande, d'adéquation à un usage particulier ou de non-violation. Nous ne faisons aucune déclaration ou garantie que :`,
        disclaimerOfWarrantiesList: [
          "Les Services répondront à vos exigences.",
          "Les Services seront ininterrompus, opportuns, sécurisés ou exempts d'erreurs.",
          "Toute information obtenue par le biais des Services sera exacte ou fiable.",
        ],
        limitationOfLiability: "12. Limitation de responsabilité",
        limitationOfLiabilityContent: `En aucun cas Digital Coupon Book Canada, ses affiliés, partenaires, employés ou agents ne seront responsables de tout dommage direct, indirect, accessoire, consécutif ou spécial, y compris, mais sans s'y limiter, la perte de revenus, de données ou d'opportunités commerciales, découlant de ou en relation avec l'utilisation ou l'impossibilité d'utiliser les Services.`,
        termination: "13. Résiliation",
        terminationContent: `Nous nous réservons le droit de résilier ou de suspendre votre accès aux Services à tout moment, avec ou sans motif, et sans préavis. En cas de résiliation, vous restez lié parsiliation, vous restez lié par ces Conditions d'utilisation dans la mesure où elles s'appliquent.`,
        changesToTerms: "14. Modifications des conditions d'utilisation",
        changesToTermsContent: `Nous pouvons mettre à jour ces Conditions d'utilisation de temps à autre. Toute modification sera effective immédiatement après sa publication sur notre site Web. En continuant à utiliser les Services, vous acceptez et approuvez les conditions révisées. Il est de votre responsabilité de consulter régulièrement ces conditions.`,
        governingLaw: "15. Loi applicable",
        governingLawContent: `Ces Conditions d'utilisation sont régies par les lois du Canada. Tout litige découlant de ou lié à ces conditions ou à votre utilisation des Services sera soumis à la juridiction exclusive des tribunaux situés en Ontario.`,
        contactUs: "16. Contactez-nous",
        contactUsContent: `Si vous avez des questions concernant ces Conditions d'utilisation, veuillez nous contacter à`,
        contactUsEmail: `support@digitalcouponbook.ca`,
      },
    };
    return translations[language][key];
  };

  return (
    <Box minH="100vh" bg="gray.50" py={10}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch" textAlign="left">
          <Flex justifyContent="space-between" alignItems="center">
            <Image src={logo} alt="Logo" boxSize="100px" />
            <Flex justify="center" align="center" width="100px" mb={4}>
              <Box
                as="button"
                py={2}
                px={3}
                onClick={() => setLanguage("en")}
                color={language === "en" ? "blue.500" : "gray.500"}
                fontWeight={language === "en" ? "bold" : "normal"}
              >
                EN
              </Box>
              <Box px={1} color="gray.300">
                |
              </Box>
              <Box
                as="button"
                py={2}
                px={3}
                onClick={() => setLanguage("fr")}
                color={language === "fr" ? "blue.500" : "gray.500"}
                fontWeight={language === "fr" ? "bold" : "normal"}
              >
                FR
              </Box>
            </Flex>
          </Flex>
          <Box mx={20}>
            <Heading as="h1" size="2xl">
              {t("title")}
            </Heading>
            <Text fontWeight="bold" mt={4}>
              {t("effectiveDate")}
            </Text>

            <Text mt={4}>{t("introduction")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("acceptanceOfTerms")}
            </Heading>
            <Text>{t("acceptanceOfTermsContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("eligibility")}
            </Heading>
            <Text>{t("eligibilityContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("servicesOverview")}
            </Heading>
            <Text>{t("servicesOverviewContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("userAccounts")}
            </Heading>
            <Text>{t("userAccountsContent")}</Text>
            <UnorderedList mt={2}>
              {t("userAccountsList")?.map((item, index) => (
                <ListItem key={index}>{item}</ListItem>
              ))}
            </UnorderedList>
            <Text mt={2}>{t("userAccountsFooter")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("useOfCoupons")}
            </Heading>
            <Text>{t("useOfCouponsContent")}</Text>
            <UnorderedList mt={2}>
              {t("useOfCouponsList")?.map((item, index) => (
                <ListItem key={index}>{item}</ListItem>
              ))}
            </UnorderedList>
            <Text mt={2}>{t("useOfCouponsFooter")}</Text>

            <Heading as="h3" size="md" mt={4} mb={2}>
              {t("diningOffers")}
            </Heading>
            <Text>{t("diningOffersContent")}</Text>
            <UnorderedList mt={2}>
              {t("diningOffersList")?.map((offer, index) => (
                <ListItem key={index}>
                  <Text as="span" fontWeight="bold">
                    {offer.title}
                  </Text>{" "}
                  {offer.content}
                </ListItem>
              ))}
            </UnorderedList>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("businessParticipation")}
            </Heading>
            <Text>{t("businessParticipationContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("fundraisingOrganizations")}
            </Heading>
            <Text>{t("fundraisingOrganizationsContent")}</Text>
            <UnorderedList mt={2}>
              {t("fundraisingOrganizationsList")?.map((item, index) => (
                <ListItem key={index}>{item}</ListItem>
              ))}
            </UnorderedList>
            <Text mt={2}>{t("fundraisingOrganizationsFooter")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("paymentsAndFees")}
            </Heading>
            <Text>{t("paymentsAndFeesContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("intellectualProperty")}
            </Heading>
            <Text>{t("intellectualPropertyContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("privacy")}
            </Heading>
            <Text>{t("privacyContent")}</Text>
            <UnorderedList mt={2}>
              {t("privacyList")?.map((item, index) => (
                <ListItem key={index}>{item}</ListItem>
              ))}
            </UnorderedList>
            <Text mt={2}>{t("privacyFooter")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("disclaimerOfWarranties")}
            </Heading>
            <Text>{t("disclaimerOfWarrantiesContent")}</Text>
            <UnorderedList mt={2}>
              {t("disclaimerOfWarrantiesList")?.map((item, index) => (
                <ListItem key={index}>{item}</ListItem>
              ))}
            </UnorderedList>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("limitationOfLiability")}
            </Heading>
            <Text>{t("limitationOfLiabilityContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("termination")}
            </Heading>
            <Text>{t("terminationContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("changesToTerms")}
            </Heading>
            <Text>{t("changesToTermsContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("governingLaw")}
            </Heading>
            <Text>{t("governingLawContent")}</Text>

            <Heading as="h2" size="lg" mt={6} mb={3}>
              {t("contactUs")}
            </Heading>
            <Text>
              {t("contactUsContent")}{" "}
              <Link href={`mailto:${t("contactUsEmail")}`} color="blue.500">
                {t("contactUsEmail")}
              </Link>
              .
            </Text>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default Terms;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Heading,
  Textarea,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import Navbar from "../components/Navbar";

const validationSchema = Yup.object().shape({
  name: Yup.object().shape({
    en: Yup.string().required("Campaign name (English) is required"),
    fr: Yup.string(),
  }),
  endDate: Yup.date().required("End date is required"),
  contactName: Yup.string().required("Contact name is required"),
  contactEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  contactPhone: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
  passcode: Yup.string()
    .min(4, "Passcode must be at least 4 characters")
    .required("Passcode is required"),
  couponBookPrice: Yup.number()
    .positive("Price must be positive")
    .required("Coupon book price is required"),
  description: Yup.object().shape({
    en: Yup.string()
      .max(1000, "Description must be at most 1000 characters")
      .required("Description (English) is required"),
    fr: Yup.string().max(1000, "Description must be at most 1000 characters"),
  }),
  offeringURL: Yup.string().url("Invalid URL").nullable(),
});

const CampaignForm = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: { en: "", fr: "" },
    endDate: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    passcode: "",
    couponBookPrice: "",
    description: { en: "", fr: "" },
    offeringURL: "",
  });

  const isEditMode = !!campaignId;

  useEffect(() => {
    const fetchCampaign = async () => {
      if (isEditMode) {
        const campaignDoc = await getDoc(doc(db, "campaigns", campaignId));
        if (campaignDoc.exists()) {
          const campaignData = campaignDoc.data();
          setInitialValues({
            ...campaignData,
            endDate: campaignData.endDate, // No conversion needed
          });
        } else {
          toast({
            title: "Error",
            description: "Campaign not found",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          navigate(-1);
        }
      }
    };

    fetchCampaign();
  }, [campaignId, isEditMode, navigate, toast]);

  const handleShowClick = () => setShowPassword(!showPassword);

  const handleSubmit = async (values, actions) => {
    try {
      let campaignRef;

      if (isEditMode) {
        campaignRef = doc(db, "campaigns", campaignId);
        await updateDoc(campaignRef, {
          ...values,
          endDate: new Date(values.endDate),
        });
      } else {
        campaignRef = await addDoc(collection(db, "campaigns"), {
          ...values,
          endDate: new Date(values.endDate),
        });

        // Create a sub-collection of agents and add an agent called "General"
        const agentRef = doc(
          collection(db, `campaigns/${campaignRef.id}/agents`)
        );
        await setDoc(agentRef, { name: "General" });
      }

      actions.setSubmitting(false);
      toast({
        title: "Success",
        description: `Campaign ${
          isEditMode ? "updated" : "created"
        } successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate(`/campaign/${isEditMode ? campaignId : campaignRef.id}`);
    } catch (error) {
      console.error(
        `Error ${isEditMode ? "updating" : "creating"} campaign: `,
        error
      );
      actions.setSubmitting(false);
      toast({
        title: "Error",
        description: `Failed to ${
          isEditMode ? "update" : "create"
        } campaign. Please try again.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack
          spacing={8}
          align="center"
          maxWidth="500px"
          margin="0 auto"
          pt={8}
        >
          <Heading as="h1" size="lg" textAlign="center" color="heading">
            {isEditMode ? "Edit Campaign" : "New Campaign"}
          </Heading>
          <Text textAlign="center" color="text">
            {isEditMode
              ? "Edit the campaign details below."
              : "Create a new campaign by filling out the form below."}
          </Text>
          <Box
            width="100%"
            bg="secondaryBackground"
            p={10}
            borderRadius="xl"
            boxShadow="md"
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {(props) => (
                <Form>
                  <VStack spacing={4}>
                    <Field name="name.en">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.name?.en && form.touched.name?.en
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="name.en">
                            Campaign Name (English)
                          </FormLabel>
                          <Input
                            {...field}
                            id="name.en"
                            placeholder="Enter campaign name in English"
                          />
                          <FormErrorMessage>
                            {form.errors.name?.en}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="name.fr">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.name?.fr && form.touched.name?.fr
                          }
                        >
                          <FormLabel htmlFor="name.fr">
                            Campaign Name (French)
                          </FormLabel>
                          <Input
                            {...field}
                            id="name.fr"
                            placeholder="Enter campaign name in French (optional)"
                          />
                          <FormErrorMessage>
                            {form.errors.name?.fr}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="endDate">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.endDate && form.touched.endDate
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="endDate">End Date</FormLabel>
                          <Input {...field} id="endDate" type="date" />
                          <FormErrorMessage>
                            {form.errors.endDate}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="contactName">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.contactName && form.touched.contactName
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="contactName">
                            Contact Name
                          </FormLabel>
                          <Input
                            {...field}
                            id="contactName"
                            placeholder="Enter contact name"
                          />
                          <FormErrorMessage>
                            {form.errors.contactName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="contactEmail">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.contactEmail &&
                            form.touched.contactEmail
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="contactEmail">
                            Contact Email
                          </FormLabel>
                          <Input
                            {...field}
                            id="contactEmail"
                            type="email"
                            placeholder="Enter contact email"
                          />
                          <FormErrorMessage>
                            {form.errors.contactEmail}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="contactPhone">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.contactPhone &&
                            form.touched.contactPhone
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="contactPhone">
                            Contact Phone
                          </FormLabel>
                          <Input
                            {...field}
                            id="contactPhone"
                            placeholder="Enter contact phone"
                          />
                          <FormErrorMessage>
                            {form.errors.contactPhone}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="passcode">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.passcode && form.touched.passcode
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="passcode">Passcode</FormLabel>
                          <InputGroup>
                            <Input
                              {...field}
                              id="passcode"
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter passcode"
                            />
                            <InputRightElement width="4.5rem">
                              <Button
                                h="1.75rem"
                                size="sm"
                                onClick={handleShowClick}
                              >
                                {showPassword ? "Hide" : "Show"}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.passcode}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="couponBookPrice">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.couponBookPrice &&
                            form.touched.couponBookPrice
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="couponBookPrice">
                            Coupon Book Price (In CAD)
                          </FormLabel>
                          <Input
                            {...field}
                            id="couponBookPrice"
                            type="number"
                            step="0.01"
                            placeholder="Enter coupon book price"
                          />
                          <FormErrorMessage>
                            {form.errors.couponBookPrice}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="description.en">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.description?.en &&
                            form.touched.description?.en
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="description.en">
                            Description (English)
                          </FormLabel>
                          <Textarea
                            {...field}
                            id="description.en"
                            placeholder="Enter campaign description in English"
                          />
                          <FormErrorMessage>
                            {form.errors.description?.en}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="description.fr">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.description?.fr &&
                            form.touched.description?.fr
                          }
                        >
                          <FormLabel htmlFor="description.fr">
                            Description (French)
                          </FormLabel>
                          <Textarea
                            {...field}
                            id="description.fr"
                            placeholder="Enter campaign description in French (optional)"
                          />
                          <FormErrorMessage>
                            {form.errors.description?.fr}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="offeringURL">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.offeringURL && form.touched.offeringURL
                          }
                        >
                          <FormLabel htmlFor="offeringURL">
                            Offering Page URL (Optional)
                          </FormLabel>
                          <Input
                            {...field}
                            id="offeringURL"
                            type="url"
                            placeholder="Enter offering page URL"
                          />
                          <FormErrorMessage>
                            {form.errors.offeringURL}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Button
                      mt={4}
                      variant={"primary"}
                      isLoading={props.isSubmitting}
                      type="submit"
                      width="100%"
                    >
                      {isEditMode ? "Update Campaign" : "Create Campaign"}
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default CampaignForm;

import "./App.css";

import BusinessForm from "./pages/BusinessForm";
import CampaignForm from "./pages/CampaignForm";
import AddCustomer from "./pages/AddCustomer";
import ValidateCoupon from "./pages/ValidateCoupon";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFailure from "./pages/PaymentFailure";
import PaymentCancellation from "./pages/PaymentCancellation";
import { AuthContextProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import CampaignHome from "./pages/CampaignHome";
import AddAgents from "./pages/AddAgents";
import customTheme from "./design/customTheme";
import Terms from "./pages/Terms";
import BusinessList from "./pages/BusinessList";
import Privacy from "./pages/Privacy";
import { LanguageProvider } from "./contexts/LanguageContext";

function App() {
  return (
    <LanguageProvider>
      <Router>
        <ChakraProvider theme={customTheme}>
          <AuthContextProvider>
            <div className="App">
              <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route
                  path="/home"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/campaign/:campaignId"
                  element={
                    <ProtectedRoute>
                      <CampaignHome />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-agents/:campaignId"
                  element={
                    <ProtectedRoute>
                      <AddAgents />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/create-campaign"
                  element={
                    <ProtectedRoute>
                      <CampaignForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/edit-campaign/:campaignId"
                  element={
                    <ProtectedRoute>
                      <CampaignForm />
                    </ProtectedRoute>
                  }
                />
                <Route path="/payment/success" element={<PaymentSuccess />} />
                <Route path="/payment/failure" element={<PaymentFailure />} />
                <Route
                  path="/payment/cancellation"
                  element={<PaymentCancellation />}
                />
                <Route
                  path="/new-customer/:campaignId?/:agentId?"
                  element={<AddCustomer />}
                />
                <Route
                  path="/business-list/:campaignId"
                  element={<BusinessList />}
                />
                <Route
                  path="/add-business"
                  element={
                    <ProtectedRoute>
                      <BusinessForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/edit-business/:businessId"
                  element={
                    <ProtectedRoute>
                      <BusinessForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/validate-coupon/:customerCode?"
                  element={<ValidateCoupon />}
                />
              </Routes>
            </div>
          </AuthContextProvider>
        </ChakraProvider>
      </Router>
    </LanguageProvider>
  );
}

export default App;

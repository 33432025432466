import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Heading,
  VStack,
  Button,
  useBreakpointValue,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Text,
  useToast,
} from "@chakra-ui/react";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import DataTable from "react-data-table-component";
import Navbar from "../components/Navbar";

const CampaignHome = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [businesses, setBusinesses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [campaignStats, setCampaignStats] = useState({
    agentCount: 0,
    businessCount: 0,
    totalAmountSold: 0,
    totalSalesCount: 0,
  });
  const toast = useToast();

  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });

  useEffect(() => {
    const fetchData = async () => {
      // Fetch campaign name
      const campaignDoc = await getDoc(doc(db, "campaigns", campaignId));
      if (campaignDoc.exists()) {
        setCampaignName(campaignDoc.data().name.en);
      }

      // Fetch businesses
      const businessesQuery = query(
        collection(db, "businesses"),
        where("campaignId", "==", campaignId)
      );
      const businessesSnapshot = await getDocs(businessesQuery);
      const businessesData = businessesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBusinesses(businessesData);

      // Fetch customers
      const customersQuery = query(
        collection(db, "customers"),
        where("campaignId", "==", campaignId)
      );
      const customersSnapshot = await getDocs(customersQuery);
      setCustomers(
        customersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );

      // Fetch agents
      const agentsQuery = query(
        collection(db, `campaigns/${campaignId}/agents`)
      );
      const agentsSnapshot = await getDocs(agentsQuery);
      const agentsData = agentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAgents(agentsData);

      // Calculate campaign stats
      const totalAmountSold = agentsData.reduce(
        (sum, agent) => sum + (agent.saleAmount || 0),
        0
      );
      const totalSalesCount = agentsData.reduce(
        (sum, agent) => sum + (agent.saleCount || 0),
        0
      );

      setCampaignStats({
        agentCount: agentsData.length,
        businessCount: businessesData.length,
        totalAmountSold,
        totalSalesCount,
      });
    };

    fetchData();
  }, [campaignId]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name?.en || "",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Website URL",
      selector: (row) => row.websiteUrl,
      sortable: true,
    },
    {
      name: "Coupon Details",
      selector: (row) => row.couponDetails?.en || "",
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => row.createdAt?.toDate().toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button
          onClick={() => navigate(`/edit-business/${row.id}`)}
          size="sm"
          variant="outline"
        >
          Edit
        </Button>
      ),
    },
  ];

  const customerColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Purchase Date",
      selector: (row) => row.createdAt?.toDate().toLocaleDateString(),
      sortable: true,
    },
  ];

  const copyToClipboard = (agentId) => {
    const url = `https://app.digitalcouponbook.ca/new-customer/${campaignId}/${agentId}`;
    // const url = `http://localhost:3000/new-customer/${campaignId}/${agentId}`;
    navigator.clipboard.writeText(url).then(() => {
      toast({
        title: "URL Copied",
        description: "The agent's URL has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const copyNewCustomerUrl = () => {
    // const url = `http://localhost:3000/new-customer/0Q5t3kOVRUlcAmFo0M7D`;
    const url = `https://app.digitalcouponbook.ca/new-customer/${campaignId}`;
    navigator.clipboard.writeText(url).then(() => {
      toast({
        title: "URL Copied",
        description: "The new customer URL has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const agentColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Sales Count",
      selector: (row) => row.saleCount || 0,
      sortable: true,
    },
    {
      name: "Sales Amount",
      selector: (row) => `$${(row.saleAmount || 0).toFixed(2)}`,
      sortable: true,
    },
    {
      name: "Purchase URL",
      cell: (row) => (
        <Button
          onClick={() => copyToClipboard(row.id)}
          size="sm"
          variant="outline"
        >
          Copy URL
        </Button>
      ),
    },
  ];

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack
          spacing={8}
          align="center"
          maxWidth="1200px"
          margin="0 auto"
          pt={8}
        >
          <Heading as="h1" size="lg" textAlign="center" color="heading">
            Campaign Dashboard
          </Heading>
          <Text textAlign="center" color="text" fontSize="xl" fontWeight="bold">
            {campaignName}
          </Text>
          <Button onClick={copyNewCustomerUrl} size="sm" variant="outline">
            Copy Purchase URL
          </Button>
          <Button
            onClick={() => navigate(`/edit-campaign/${campaignId}`)}
            size="sm"
            variant="outline"
          >
            Edit Campaign
          </Button>

          <Box
            width="100%"
            bg="secondaryBackground"
            p={10}
            borderRadius="xl"
            boxShadow="md"
          >
            <VStack spacing={6} align="stretch">
              <StatGroup backgroundColor="gray.100" borderRadius="md" p={4}>
                <Stat>
                  <StatLabel>Fundraisers</StatLabel>
                  <StatNumber>{campaignStats.agentCount}</StatNumber>
                </Stat>
                <Stat>
                  <StatLabel>Advertisers</StatLabel>
                  <StatNumber>{campaignStats.businessCount}</StatNumber>
                </Stat>

                <Stat>
                  <StatLabel>Supporters</StatLabel>
                  <StatNumber>{campaignStats.totalSalesCount}</StatNumber>
                </Stat>
                <Stat>
                  <StatLabel>Total Amount Sold</StatLabel>
                  <StatNumber>
                    ${campaignStats.totalAmountSold.toFixed(2)}
                  </StatNumber>
                </Stat>
              </StatGroup>

              <Flex justify="space-between" width="100%">
                <Button
                  onClick={() => navigate(`/add-business`)}
                  size={buttonSize}
                  width="48%"
                  variant={"primary"}
                >
                  Add Advertiser
                </Button>

                <Button
                  onClick={() => navigate(`/add-agents/${campaignId}`)}
                  variant={"primary"}
                  size={buttonSize}
                  width="48%"
                >
                  Add Fundraisers
                </Button>
              </Flex>

              <Box>
                <Heading
                  as="h2"
                  size="md"
                  mb={4}
                  textAlign="left"
                  color="heading"
                >
                  Advertisers
                </Heading>
                <Box overflowX="auto">
                  <DataTable
                    columns={columns}
                    data={businesses}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 30]}
                    highlightOnHover
                    striped
                    responsive
                  />
                </Box>
              </Box>

              <Box>
                <Heading
                  as="h2"
                  size="md"
                  mb={4}
                  textAlign="left"
                  color="heading"
                >
                  Fundraisers
                </Heading>
                <Box overflowX="auto">
                  <DataTable
                    columns={agentColumns}
                    data={agents}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 30]}
                    highlightOnHover
                    striped
                    responsive
                  />
                </Box>
              </Box>

              <Box>
                <Heading
                  as="h2"
                  size="md"
                  mb={4}
                  textAlign="left"
                  color="heading"
                >
                  Supporters
                </Heading>
                <Box overflowX="auto">
                  <DataTable
                    columns={customerColumns}
                    data={customers}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 30]}
                    highlightOnHover
                    striped
                    responsive
                  />
                </Box>
              </Box>
            </VStack>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default CampaignHome;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  VStack,
  Heading,
  Text,
  Link,
  Spinner,
  Flex,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import { db, storage } from "../firebase";
import logo from "../assets/logos/logo.png";
import { useLanguage } from "../contexts/LanguageContext";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { FaStore } from "react-icons/fa";

const BusinessList = () => {
  const { campaignId } = useParams();
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [campaignName, setCampaignName] = useState("");
  const { language, setLanguage, translate } = useLanguage();

  useEffect(() => {
    const fetchBusinessesAndCampaign = async () => {
      try {
        // Fetch campaign name
        const campaignRef = doc(db, "campaigns", campaignId);
        const campaignSnap = await getDoc(campaignRef);
        if (campaignSnap.exists()) {
          setCampaignName(campaignSnap.data().name);
        } else {
          setError("Campaign not found.");
          return;
        }

        // Fetch businesses
        const businessesRef = collection(db, "businesses");
        const q = query(businessesRef, where("campaignId", "==", campaignId));
        const querySnapshot = await getDocs(q);
        const businessList = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const businessData = doc.data();
            const businessId = doc.id;
            let logoUrl = null;

            try {
              const logoRef = ref(storage, `business-logos/${businessId}`);
              logoUrl = await getDownloadURL(logoRef);
            } catch (error) {
              console.error(
                `Error fetching logo for business ${businessId}:`,
                error
              );
            }

            return {
              id: businessId,
              ...businessData,
              logoUrl,
            };
          })
        );
        setBusinesses(businessList);
      } catch (err) {
        console.error("Error fetching data: ", err);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessesAndCampaign();
  }, [campaignId]);

  // Helper function to get the localized text with fallback
  const getLocalizedText = (field) => {
    if (typeof field === "object" && field !== null) {
      return field[language] || field.en || "";
    }
    return field || "";
  };

  if (loading) {
    return (
      <Box
        minH="100vh"
        bg="primaryBackground"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" color="blue.500" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        minH="100vh"
        bg="primaryBackground"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      bg="primaryBackground"
    >
      <Flex justify="space-between" align="center" p={4} flexWrap="wrap">
        <Image src={logo} alt="Logo" width={["80px", "100px"]} />
        <Flex align="center" mt={[4, 0]}>
          <Box
            as="button"
            py={2}
            px={3}
            onClick={() => setLanguage("en")}
            color={language === "en" ? "blue.500" : "gray.500"}
            fontWeight={language === "en" ? "bold" : "normal"}
          >
            EN
          </Box>
          <Box px={1} color="gray.300">
            |
          </Box>
          <Box
            as="button"
            py={2}
            px={3}
            onClick={() => setLanguage("fr")}
            color={language === "fr" ? "blue.500" : "gray.500"}
            fontWeight={language === "fr" ? "bold" : "normal"}
          >
            FR
          </Box>
        </Flex>
      </Flex>

      <VStack
        align="stretch"
        maxWidth="1200px"
        margin="0 auto"
        pt={8}
        px={4}
        pb={8}
      >
        <Heading
          as="h1"
          size={["lg", "xl"]}
          textAlign="center"
          color="text"
          mt={10}
        >
          {translate("businessList.heading")}
        </Heading>
        <Heading
          as="h3"
          size={["sm", "md"]}
          color="heading"
          mb={5}
          textAlign="center"
        >
          {getLocalizedText(campaignName)}
        </Heading>
        <Text mb={10} textAlign="center" fontSize={["sm", "md"]}>
          {translate("businessList.description", {
            campaignName: getLocalizedText(campaignName),
          })}
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {businesses.map((business) => (
            <Box
              key={business.id}
              p={6}
              bg="secondaryBackground"
              borderRadius="xl"
              boxShadow="md"
              display="flex"
              flexDirection="column"
              height="100%"
            >
              <Flex direction="column" height="100%">
                <Box height="100px" mb={3}>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    {business.logoUrl ? (
                      <Image
                        src={business.logoUrl}
                        alt={`${getLocalizedText(business.name)} logo`}
                        maxWidth="100px"
                        maxHeight="100px"
                        objectFit="contain"
                      />
                    ) : (
                      <FaStore size="50px" color="gray.300" />
                    )}
                  </Flex>
                </Box>
                <VStack align="stretch" spacing={3} flexGrow={1}>
                  <Heading as="h3" size="md" color="text">
                    {getLocalizedText(business.name)}
                  </Heading>
                  <Text color="text">
                    {getLocalizedText(business.description)}
                  </Text>
                  <Link href={business.websiteUrl} isExternal color="blue.500">
                    {translate("businessList.website")}
                  </Link>
                  <Text fontWeight="bold" color="text">
                    {translate("businessList.couponDetails")}:
                  </Text>
                  <Text color="text">
                    {getLocalizedText(business.couponDetails)}
                  </Text>
                </VStack>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default BusinessList;
